import React from 'react'

import InnerPage, { Section } from '../components/InnerPage'
import PageHeader from '../components/PageHeader'

function Kontakt() {
    return (
        <InnerPage title="Kontakt">
            <PageHeader title="Kontakt" />
            <Section>
                <div className="container">
                    <div className="row gutters-50">
                        <div className="col-lg-8">
                        <p>
                            <b>
                                Bahá’í­-Vertretung Berlin
                                <br />
                            </b>
                            Chausseestr. 103
                            <br />
                            10115 Berlin
                        </p>
                        <p>
                            Tel.: 030/288 77 183
                            <br />
                            Fax: 030/69 08 82 61
                            <br />
                            E-Mail:{' '}
                            <a class="mail" href="mailto:oea@bahai.de">
                                oea@bahai.de
                            </a>
                        </p>
                        </div>
                    </div>
                </div>
            </Section>
        </InnerPage>
    )
}

export default Kontakt